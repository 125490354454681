import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },

  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'certificate',
    loadChildren: () => import('./certificate/certificate.module').then( m => m.CertificatePageModule)
  },
  {
    path: 'exam-form/:center',
    loadChildren: () => import('./exam-form/exam-form.module').then( m => m.ExamFormPageModule)
  },
  {
    path: 'questions',
    loadChildren: () => import('./questions/questions.module').then( m => m.QuestionsPageModule)
  },
  {
    path: 'ccc-exam/:center',
    loadChildren: () => import('./ccc-exam/ccc-exam.module').then( m => m.CccExamPageModule)
  },  {
    path: 'demo-exam-form',
    loadChildren: () => import('./demo-exam-form/demo-exam-form.module').then( m => m.DemoExamFormPageModule)
  },
  {
    path: 'demo-questions',
    loadChildren: () => import('./demo-questions/demo-questions.module').then( m => m.DemoQuestionsPageModule)
  },




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
